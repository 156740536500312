<template>
	<b-card no-body class="cart-card">
		<b-row align-v="center" class="py-1" no-gutters>
			<!-- Image -->
			<b-col cols="3">
				<b-card class="ml-2 image-card" body-class="p-0">
					<b-img :src="imageSrc" style="height: 100%" fluid center />
				</b-card>
			</b-col>

			<!-- Cart Data -->
			<b-col cols="9">
				<div class="mx-2 d-flex align-items-center">
					<!-- Product Title, Quantity & Unit -->
					<div class="flex-grow-1 d-flex flex-column">
						<!-- Product Title -->
						<div class="w-90 cart-product-title ">
							{{ product.name }}
						</div>

						<!-- Quantity & Unit -->
						<div class="cart-unit">{{ quantity }} {{ unit }}</div>
					</div>

					<!-- Price -->
					<div class="cart-price text-right">
						{{ totalPriceToDisplay | rupeeCurrency }}
					</div>
				</div>
			</b-col>

			<!-- Collapse Stretched Link -->
			<a href="#" @click.prevent="toggleCollapse" class="stretched-link"> </a>
		</b-row>

		<!-- Collapse to change quantity -->
		<b-row class="collapsed-row">
			<b-col>
				<b-collapse v-model="collapse" hide-header hide-footer>
					<div class="d-flex justify-content-between align-items-center m-2">
						<!-- Close Collapse -->
						<div>
							<b-icon-arrows-collapse @click="closeCollapse" class="cursor-pointer" scale="1.3" />
						</div>

						<!-- Quantity Spinner -->
						<div>
							<b-form-spinbutton @change="onChangeQuantity" :value="quantity" size="sm" :min="quantitySpinData(unit).min" :step="quantitySpinData(unit).increment" :max="quantitySpinData(unit).max" inline />
						</div>

						<!-- Delete -->
						<div>
							<b-icon-trash-fill @click="deleteCartItem" class="cursor-pointer" variant="danger" scale="1.3" />
						</div>
					</div>
				</b-collapse>
			</b-col>
		</b-row>
	</b-card>
</template>

<script>
import { quantitySpinData } from "../../app-functions";
import { mapActions, mapGetters } from "vuex";
import { deleteItemFromCart, updateItemQuantity } from "../../apis/user";

export default {
	props: {
		cartItem: {
			type: Object,
			required: true,
		},
	},
	created() {
		// get images
		this.getGuestProductImages({
			storeUrlName: this.storeUrlName,
			productId: this.product.id,
		}).then((images) => (this.productImages = [...images]));
	},
	data() {
		return {
			collapse: false,

			// product images
			productImages: [],
		};
	},
	computed: {
		...mapGetters({
			storeUrlName: "guest/store/getStoreUrlName",
			store: "guest/store/getStore",
			token: "login/getToken",
		}),
		product() {
			return this.cartItem.product;
		},
		unit() {
			return this.product.unit;
		},
		quantity() {
			return this.cartItem.quantity;
		},
		totalPriceToDisplay() {
			const product = this.product;
			if (!product.price) {
				return "**";
			}
			const totalPrice = this.cartItem.quantity * product.price;
			return totalPrice.toFixed(2);
		},

		imageSrc() {
			const productImages = this.productImages;
			if (productImages.length > 0) {
				return productImages[0].src;
			}
			return null;
		},
	},
	methods: {
		quantitySpinData,
		...mapActions({
			addCartItem: "user/cartItems/addItem",
			removeCartItem: "user/cartItems/removeItem",
			getGuestProductImages: "productImages/getGuestProductImages",

			// Guest cart items
			updateGuestCartItem: "guest/carts/updateCartItem",
			deleteGuestCartItem: "guest/carts/deleteProductFromCart",
		}),

		toggleCollapse() {
			this.collapse = !this.collapse;
		},
		closeCollapse() {
			this.collapse = false;
		},
		onChangeQuantity(quantity) {
			if (quantity != 0) {
				if (this.token) {
					// updating only quantity
					updateItemQuantity(this.storeUrlName, this.cartItem.id, quantity).then((data) => {
						// emitting first, since remove cart item first wont execute the emit function in the parent since the items is removed
						this.updateCart();
						this.addCartItem(data);
					});
				} else {
					// Update cartItem in guest cart
					this.updateGuestCartItem({ product: this.product, quantity: quantity }).then(this.updateCart);
				}
			}
		},
		deleteCartItem() {
			if (this.token) {
				deleteItemFromCart(this.storeUrlName, this.cartItem.id).then(() => {
					// emitting first, since remove cart item first wont execute the emit function in the parent since the items is removed
					this.updateCart();
					this.removeCartItem({ id: this.cartItem.id });
				});
			} else {
				// Remove cartItem from guestCart
				this.deleteGuestCartItem({ productId: this.product.id }).then(this.updateCart);
			}
		},
		updateCart() {
			this.$emit("cartUpdated");
		},
	},
};
</script>

<style scoped>
.collapsed-row {
	z-index: 5;
}
.w-90 {
	width: 90%;
}
.image-card {
	height: 60px;
}
</style>

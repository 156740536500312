<template>
	<b-row>
		<b-col md="6" lg="4" v-for="cartPrescription in cartPrescriptions" :key="cartPrescription.id">
			<cart-prescription-card :cartPrescription="cartPrescription" class="my-2" />
		</b-col>
	</b-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CartPrescriptionCard from "./CartPrescriptionCard.vue";
export default {
	components: { CartPrescriptionCard },
	created() {
		this.getCartPrescriptionsApi();
	},
	computed: {
		...mapGetters({
			cartPrescriptions: "user/userCartPrescriptions/getCartPrescriptions",
		}),
	},
	methods: {
		...mapActions({
			getCartPrescriptionsApi: "user/userCartPrescriptions/getCartPrescriptions",
		}),
	},
};
</script>

<style></style>

<template>
	<b-card class="border-0 mt-3" header-tag="h5" header-class="rounded-pill bg-white text-center pb-4">
		<!-- Header -->
		<template #header> Cart </template>

		<!-- Cart Items & Costing -->
		<template v-if="!isCartEmpty">
			<!-- Cart Prescriptions -->
			<template v-if="token && enablePrescription">
				<cart-prescriptions />

				<hr v-if="hasCartPrecriptions" />
			</template>

			<div class="cart-items">
				<b-row>
					<b-col md="6" lg="4" v-for="cartItem of cartItemsToUse" :key="cartItem.id">
						<CartItem :cartItem="cartItem" class="mb-3" @cartUpdated="onCartUpdated" />
					</b-col>
				</b-row>

				<b-row v-if="hasItemWithNoPrice">
					<b-col>
						<MarketPrice class="small mt-2" />
					</b-col>
				</b-row>
			</div>

			<div class="costing pt-4">
				<!-- Item Price -->
				<div class="d-flex justify-content-between">
					<div>Sub-total</div>
					<div v-if="hasItemWithNoPrice">
						{{ totalItemsPrice.toString() | rupeeCurrency }} +
						{{ "**" | rupeeCurrency }}
					</div>
					<div v-else>{{ totalItemsPrice.toString() | rupeeCurrency }}</div>
				</div>

				<!-- Delivery -->
				<div class="d-flex justify-content-between">
					<div>Delivery</div>
					<div>{{ delivery.toString() | rupeeCurrency }}</div>
				</div>

				<!-- Grand Total -->
				<div class="d-flex justify-content-between font-weight-bold">
					<div>Total</div>
					<div v-if="hasItemWithNoPrice">
						{{ grandTotal.toString() | rupeeCurrency }} +
						{{ "**" | rupeeCurrency }}
					</div>
					<div v-else>{{ grandTotal.toString() | rupeeCurrency }}</div>
				</div>

				<!-- Place Order Button -->
				<b-button @click="onCheckout" variant="warning" class="text-uppercase mt-3" size="lg" block pill> <b-icon-arrow-right scale="0.7" />Checkout </b-button>
			</div>
		</template>

		<!-- Cart is Empty, display -->
		<template v-else>
			<b-alert show variant="info" class="text-center">
				<h4 class="alert-heading">Your Cart is Empty!</h4>
				<p>You don't have any products in your cart.</p>

				<hr />

				<b-button @click="onBackToShoppingClicked" class="font-weight-bold" pill>
					Back to shopping
				</b-button>
			</b-alert>
		</template>
	</b-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getCartItems } from "../../../apis/user";
import CartItem from "../../../components/cart/CartItem.vue";
import MarketPrice from "../../../components/common/MarketPrice.vue";
import CartPrescriptions from "../../../components/prescriptions/CartPrescriptions.vue";

export default {
	components: { CartItem, MarketPrice, CartPrescriptions },
	mounted() {
		if (this.store) {
			this.initCartItems();
		}
	},
	data() {
		return {
			cart: {
				totalItemsPrice: 0,
				deliveryCost: 0,
				totalAmount: 0,
				hasItemWithNoPrice: false,
			},
		};
	},
	computed: {
		...mapGetters({
			token: "login/getToken",
			store: "guest/store/getStore",
			storeUrlName: "guest/store/getStoreUrlName",
			getCartId: "user/cart/getCartId",
			cartItems: "user/cartItems/getList",

			// delivery settings
			deliverySettings: "guest/store/getStore",

			// guest cart
			guestCartItems: "guest/carts/getCartItems",

			// Prescription changes
			enablePrescription: "getEnablePrescription",
			cartPrescriptions: "user/userCartPrescriptions/getCartPrescriptions",
		}),
		cartId() {
			if (this.storeUrlName) {
				return this.getCartId(this.storeUrlName);
			}
			return null;
		},
		cartItemsToUse() {
			if (this.token) {
				return this.cartItems;
			}
			return this.guestCartItems;
		},
		hasCartPrecriptions() {
			return this.cartPrescriptions.length > 0;
		},
		isCartEmpty() {
			if (this.hasCartPrecriptions) return false;
			if (this.cartItemsToUse.length > 0) return false;
			return true;
		},
		hasItemWithNoPrice() {
			// if user based cart, get from cart.
			if (this.token) {
				const cart = this.cart;
				return cart ? cart.hasItemWithNoPrice : false;
			}
			// else, loop through the guest cart items, for each item check if product has no price
			else {
				const itemWithNoPrice = [...this.guestCartItems].find((cartItem) => {
					return cartItem.product && !cartItem.product.price;
				});
				return Boolean(itemWithNoPrice);
			}
		},
		totalItemsPrice() {
			if (this.token) {
				const cart = this.cart;
				if (cart && cart.totalItemsPrice) {
					return parseFloat(cart.totalItemsPrice).toFixed(2);
				}
				return this.grandTotal - this.delivery;
			} else {
				const itemPrices = [...this.guestCartItems].map((item) => item.product.price * item.quantity);
				if (itemPrices.length > 0) return itemPrices.reduce((total, price) => total + price);
				return 0;
			}
		},
		delivery() {
			if (this.token) {
				const cart = this.cart;
				// fetching from cart
				if (cart && cart.deliveryCost) {
					return parseFloat(cart.deliveryCost).toFixed(2);
				}
				// no delivery cost
				return 0;
			} else {
				const deliverySettings = this.store.deliverySettings;
				if (deliverySettings && deliverySettings.deliveryFee) {
					const totalItemsPrice = this.totalItemsPrice;
					if (totalItemsPrice <= deliverySettings.freeAboveAmount) {
						return deliverySettings.deliveryFee;
					}
				}
				return 0;
			}
		},
		grandTotal() {
			const cart = this.cart;
			// fetching from cart
			if (cart && cart.totalAmount) {
				return parseFloat(cart.totalAmount).toFixed(2);
			}
			return 0;
		},
	},
	watch: {
		store: function() {
			this.initCartItems();
		},
	},
	methods: {
		...mapActions({
			setCartItems: "user/cartItems/setList",
			getCartApi: "user/cart/getCart",
		}),
		initCartItems() {
			const storeUrlName = this.storeUrlName;
			if (this.token) {
				const cartId = this.getCartId(storeUrlName);
				getCartItems(storeUrlName, cartId).then((data) => {
					this.setCartItems(data);
				});
			}
			// update cart data
			this.onCartUpdated();
		},
		onCheckout() {
			if (this.token) {
				// redirect to place order
				this.$router.push({ name: "CartPlaceOrder" });
			} else {
				// redirect to checkout login
				this.$router.push({ name: "CartLogin" });
			}
		},
		onBackToShoppingClicked() {
			this.$router.push({ name: "StoreDashboard" });
		},
		onCartUpdated() {
			// make request to get cart, to update data
			if (this.token) {
				this.getCartApi().then((data) => {
					this.cart = { ...data };
				});
			} else {
				// totalItemsPrice
				const totalItemsPrice = this.totalItemsPrice;
				// deliveryCost
				const deliveryCost = this.delivery;
				// hasItemWithNoPrice
				const hasItemWithNoPrice = this.hasItemWithNoPrice;
				// Set cart
				const cart = {
					totalItemsPrice,
					deliveryCost,
					totalAmount: totalItemsPrice + deliveryCost,
					hasItemWithNoPrice,
				};
				this.cart = { ...cart };
			}
		},
	},
};
</script>

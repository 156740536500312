<template>
	<b-card no-body class="cart-card">
		<b-row align-v="center" class="py-1" no-gutters>
			<!-- Image -->
			<b-col cols="3">
				<b-card class="image-card" body-class="p-0 my-2">
					<b-img v-bind="miniImageProps" :src="imageUrl" @click="showFullImageModal = true" class="fit cursor-pointer" alt="Prescription Image" />
				</b-card>
			</b-col>

			<!-- Info -->
			<b-col cols="9">
				<div class="mx-2 d-flex align-items-center">
					<!-- Product Title, Quantity & Unit -->
					<div class="flex-grow-1 d-flex flex-column">
						<!-- Prescription Name -->
						<div class="cart-product-title ">
							{{ name }}
						</div>

						<div class="cart-unit">(Prescription)</div>
					</div>

					<div>
						<b-icon-trash-fill @click="deleteCartPrescription" class="cursor-pointer" variant="danger" scale="1.3" />
					</div>
				</div>
			</b-col>
		</b-row>

		<!-- Full Image Modal -->
		<b-modal :title="name" size="md" v-model="showFullImageModal" hide-footer no-close-on-backdrop centered>
			<b-img-lazy v-bind="fullImageProps" :src="imageUrl" />
		</b-modal>
	</b-card>
</template>

<script>
import { mapActions } from "vuex";
export default {
	props: ["cartPrescription"],
	data() {
		return {
			miniImageProps: { height: 60, center: true },
			fullImageProps: { width: 300, height: 300, fluid: true, center: true },
			showFullImageModal: false,
		};
	},
	computed: {
		name() {
			return this.userPrescription.name;
		},
		userPrescription() {
			return this.cartPrescription.userPrescription;
		},
		imageUrl() {
			return this.userPrescription.prescriptionImage.blobUrl;
		},
	},
	methods: {
		...mapActions({
			deleteCartPrescriptionApi: "user/userCartPrescriptions/deleteCartPrescription",
		}),
		deleteCartPrescription() {
			this.deleteCartPrescriptionApi(this.cartPrescription.id);
		},
	},
};
</script>

<style></style>
